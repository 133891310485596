<template>
  <div class="cond-item-customer">
    <el-row :gutter="20">
      <!-- 序号 -->
      <el-col :span="1" class="index-wrap">
        <div class="index">{{ index + 1 }}</div>
      </el-col>
      <!-- 第一列：变量名 -->
      <el-col :span="7">
        <el-input
          :disabled="detail.variableDisabled"
          v-model="detail.variable"
          placeholder="输入变量名称"
        ></el-input>
      </el-col>
      <!-- 第二列：客户属性 -->
      <el-col :span="7">
        <el-select
          v-model="detail.value"
          @change="handleFieldChange"
          placeholder="请选择或搜索变量"
          style="width: 100%"
          filterable
        >
          <el-option
            v-for="item in fields"
            :key="item.APIName"
            :label="item.Name"
            :value="item.APIName"
          ></el-option>
        </el-select>
      </el-col>
      <!-- 第三列：默认值 -->
      <el-col :span="7">
        <el-input v-model="detail.default" placeholder="输入默认值"></el-input>
      </el-col>
      <el-col :span="2" style="line-height: 36px" v-if="!detail.canNotClose">
        <i class="el-icon-close" @click="handleRemove"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleRemove() {
      this.$emit("remove", this.detail);
    },
    handleFieldChange() {
      this.detail.default = "";
    },
  },
};
</script>

<style lang="less" scoped>
.cond-item-customer {
  margin-bottom: 14px;
  .index-wrap {
  }
  .index {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-top: 7px;
    font-size: 12px;
    background: #f6f7f8;
    border-radius: 50%;
    text-align: center;
  }
  .el-icon-close {
    font-weight: 500;
    color: #9a9a9e;
    cursor: pointer;
  }
}
</style>
