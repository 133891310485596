<template>
  <div class="box">
    <div class="box-content">
      <div v-for="item in contentArr" :key="item.index" class="box-item">
        <img class="avatar" src="@/assets/img/avatar.jpg" />
        <div v-if="item.type === 'string'" class="text">
        {{ item.content }}
        </div>
        <div v-if="item.type === 'imgText'" class="img-text">
          <div class="title">{{ item.title }}</div>
          <div class="img-text-desc">
            <div class="img-text-desc-text">
              {{ item.desc }}
            </div>
            <img :src="item.img_url" alt="" />
          </div>
        </div>

        <div class="m-app" v-if="item.type === 'mApp'">
          <div class="title">{{ item.title }}</div>
          <img :src="item.img_url" alt="" class="m-app-img" />
          <div class="m-app-footer">
            <img
              src="@/assets/svg/mApp.svg"
              class="m-app-icon"
              alt=""
              @click="mAppVisible = true"
            />
            <span>小程序</span>
          </div>
        </div>

        <img
          v-if="['video', 'voice', 'file'].indexOf(item.type) > -1"
          src="@/assets/img/video.png"
          alt=""
          style="width: 166px"
        />
        <img
          v-if="item.type === 'image'"
          :src="item.img_url"
          alt=""
          :width="item.width"
          :height="item.height"
        />
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/util/index";

export default {
  components: {},
  props: {
    conpanyWxSendContent: {
      type: Array,
    },
  },
  data() {
    return {
      contentArr: [],
    };
  },
  watch: {
    // type: string , url ,video, miniprogram，image,file,voice
    // val: { content: '', type: 'string' }
    conpanyWxSendContent(val) {
      if (val) {
        this.contentArr = val;
        this.contentArr.forEach((item) => {
          if (item.type === "image") {
            item.width = 0;
            item.height = 0;
            this.autoResizeImg(item, item.img_url);
          }
        });
      }
    },
  },
  methods: {
    autoResizeImg(item, pictureUrl) {
      const img = new Image();
      img.src = pictureUrl;
      img.onload = () => {
        const obj = util.autoResizeImg(166, 80, img.width, img.height);
        item.width = obj.width;
        item.height = obj.height;
        this.$forceUpdate();
      };
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 300px;
  height: 594px;
  padding-top: 56px;
  padding-left: 34px;
  background: url(../../../assets/img/Vector.png);
  &-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 6px 10px 0 0;
    }
  }
}

.box-content {
  overflow-y: auto;
  height: 100%;
  padding-top: 20px;
}

.text {
  max-width: 166px;
  border-radius: 10px;
  background: #f3f5f6;
  word-break: break-all;

  padding: 14px;
  font-size: 12px;
  min-height: 44px;
  white-space: pre-line;
}
.img-text {
  width: 190px;
  border-radius: 10px;
  background: #f3f5f6;
  padding: 14px;
  &-desc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-text {
      color: #747378;
      font-size: 12px;
      line-height: 19px;
      margin-right: 8px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /*超出3行部分显示省略号，去掉该属性 显示全部*/
      -webkit-box-orient: vertical;
    }
    img {
      width: 52px;
      height: 52px;
      object-fit: cover;
    }
  }
  .title {
    color: #26262c;
    font-size: 12px;
    line-height: 19px;
    word-break: break-all;
  }
}
.m-app {
  width: 190px;
  border-radius: 10px;
  background: #f3f5f6;
  padding: 10px 16px;
  font-size: 12px;
  color: #747378;
  .title {
    margin-bottom: 8px;
  }
  .m-app-img {
    width: 100%;
    // height: 80px;
  }
  .m-app-footer {
    margin-top: 6px;
    display: flex;
    align-items: center;
    .m-app-icon {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }
}
</style>
