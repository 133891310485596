<template>
  <div class="box">
    <div class="box-content">
      <div class="content">
        <div class="header">
          <img
            src="@/assets/svg/appIcon.svg"
            class="app-icon"
            alt=""
            @click="mAppVisible = true"
          />
          {{ name }}
        </div>
        <div class="title">{{ appSendContent.title }}</div>
        <div class="desc">{{ appSendContent.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    appSendContent: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      content: [],
    };
  },
  watch: {
    appSendContent(val) {
      if (val) {
        this.content = val;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 300px;
  height: 594px;
  padding: 56px 16px 0 18px;
  background: url(../../../assets/img/Vector.png);
}

.box-content {
  height: 100%;
  width: 100%;
  background: url(../../../assets/img/app-bg.png) top center no-repeat;
  background-size: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 24px;
}
.content {
  // height: 87px;
  width: 211px;
  background: #f3f5f6;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  .header {
    display: flex;
    align-items: center;
    color: #747378;
    .app-icon {
      margin-right: 5px;
    }
  }
  .title {
    line-height: 19px;

    color: #26262c;
  }
  .desc {
    margin-top: 4px;
    color: #747378;
    line-height: 19px;
  }
}
</style>
