<template>
  <el-form
    :model="formData"
    :rules="formRule"
    ref="form"
    class="edit-form"
    size="small"
    label-width="100px"
  >
    <el-form-item label="推送内容" prop="content">
      <div style="margin-bottom: 13px">
        <el-select
          @change="handleContentFieldChange"
          :value="contentField"
          placeholder="请选择需要插入的字段"
          style="width: 260px"
        >
          <el-option
            v-for="item in fields"
            :key="item.APIName"
            :label="item.Name"
            :value="item.APIName"
          ></el-option>
        </el-select>

        <span class="textTip" style="margin-left: 22px"
          >+ 插入所选字段到下面的短信内容框中</span
        >
      </div>
      <div style="margin-bottom: 13px">
        <el-input
          v-model="formData.RedirectURL"
          placeholder="请输入链接内容"
          style="width: 260px"
        ></el-input>
        <span class="textTip" style="cursor: pointer" @click="addRedirectURL"
          >+ 插入所填链接到下面的短信内容框中</span
        >
      </div>
      <div
        v-if="SMSChannelType === '永达自定义'"
        style="
          font-size: 12px;
          margin-top: -10px;
          line-height: 18px;
          word-break: keep-all;
        "
      >
        非通过系统易企秀模块生成的链接需要通过企业微信进行报备，报备路劲如下：企业微信/工作台-->办公·信息/更多-->新建流程-->点开“系统账号管理”，选择“永达CDP权限系统”-->选择“CDP系统短信短链报备”并在备注中填写报备的短链
      </div>
      <div style="margin-top: 10px">短信内容框<div style="color: red">(提示：请在短信正文末添加“退订回T”，否则短信会发送失败)</div></div>
      <el-input
        type="textarea"
        placeholder="请输入短信内容"
        v-model="formData.content"
        maxlength="4000"
        show-word-limit
        rows="10"
        class="inputBox"
      />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    SMSChannelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        content: "",
        RedirectURL: "",
      },
      formRule: {
        content: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      },
      contentField: "",
    };
  },
  methods: {
    // 注意：外部调用时候，必须保证 customLink 有值了，即appKeys已经获取到了
    setDefaultValue(formData) {
      if (formData) {
        this.formData.content = formData.TemplateParam;
        this.formData.RedirectURL = formData.RedirectURL;
      }
    },
    handleContentFieldChange(value) {
      this.contentField = value;
      this.formData.content += "{{" + this.contentField + "}}\n";
    },
    addRedirectURL() {
      if (!this.formData.RedirectURL) {
        this.$message.warning("请输入链接内容！");
        return;
      }
      if (this.formData.content.indexOf("#short_url#") === -1) {
        this.formData.content += "#short_url#";
      } else {
        this.$message.warning(
          "短链内容已存在，如需修改请先删除内容中的#short_url#字样！"
        );
      }
    },
    getFormData() {
      let data = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          data = {
            TemplateParam: this.formData.content,
            RedirectURL: this.formData.RedirectURL,
          };
        }
      });

      return data;
    },
    resetForm() {
      this.formData = {
        content: "",
        RedirectURL: "",
      };
      this.contentField = "";
    },
  },
};
</script>

<style lang="less" scoped>
.textTip {
  color: #0483ec;
  margin-left: 20px;
  font-size: 12px;
}
.inputBox {
  width: 465px;
}
</style>
