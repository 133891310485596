<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['素材和渠道集成', '内容管理']">
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          editType = 'add';
        "
        v-if="
          menuPermission &&
          menuPermission.内容管理 &&
          menuPermission.内容管理.create
        "
      >
        <i class="el-icon-plus"></i>
        <span>新增内容</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <!-- 筛选条件 -->
      <div class="query-form" v-if="showQuery">
        <i class="el-icon-close" @click="showQuery = false"></i>
        <el-form
          :inline="true"
          v-if="showQuery"
          label-width="100px"
          ref="ruleForm"
          :model="formData"
        >
          <el-form-item label="内容名称:">
            <el-input
              placeholder="内容名称"
              v-model="formData.Name"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="创建者:">
            <el-select
              v-model="formData.CreatedById"
              style="width: 200px"
              clearable
              filterable
              :loading="userLoading"
            >
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in userList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容渠道" prop="MessageChannel">
            <el-select
              v-model="formData.MessageChannel"
              placeholder="请选择内容渠道"
              style="width: 200px"
              filterable
              clearable
            >
              <el-option
                v-for="item in channelActiveList"
                :label="item.Name"
                :value="item._id"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间介于:">
            <el-date-picker
              v-model="formData.StartTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-button size="medium" type="info" @click="handleQuery" round
            >应用</el-button
          >
        </el-form>
      </div>
      <div class="headOperation">
        <img
          @click="showQuery = !showQuery"
          class="showQuery"
          src="@/assets/img/query-more.png"
          alt=""
        />
      </div>

      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column prop="Name" label="内容名称"> </el-table-column>
        <el-table-column prop="MessageChannelName" label="内容渠道">
        </el-table-column>
        <!-- <el-table-column prop="MessageType" label="内容类型" width="120">
        </el-table-column> -->
        <el-table-column prop="MessageTags" label="内容标签">
          <template slot-scope="scope">
            <el-tag
              v-for="(tag, index) in scope.row.MessageTags"
              :key="index"
              style="margin-right: 8px; margin-bottom: 6px"
            >
              {{ tag }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreatedByUser" label="创建者"> </el-table-column>
        <el-table-column prop="CreatedDate" label="创建时间"> </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button type="text" @click="handleEdit(scope.row)">
                <i class="iconfont icon-edit"></i>
              </el-button>
              <el-dropdown @command="handleCommand($event, scope.row)">
                <span>
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="test"
                    ><i class="iconfont icon-mobile"></i>测试</el-dropdown-item
                  >
                  <el-dropdown-item command="delete"
                    ><i class="iconfont icon-delete"></i>删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      v-if="existDrawer"
      :exist.sync="existDrawer"
      :saveType="editType"
      :MessageChannelType="MessageChannelType"
      :detail="curTemplate"
      @success="handleSaved"
    ></edit-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该消息模板吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="测试消息模板" :visible.sync="testConfirmShow" width="30%">
      <div
        class="test-form-item"
        v-if="curTemplate.MessageChannelType === '短信'"
      >
        <label>手机号码</label>
        <el-input v-model="toUser" placeholder="请输入手机号码"></el-input>
      </div>
      <div
        class="test-form-item"
        v-else-if="curTemplate.MessageChannelType === '微信公众号'"
      >
        <label>公众号openId</label>
        <el-input
          v-model="toUser"
          placeholder="请输入微信公众号openId"
        ></el-input>
      </div>
      <div v-else-if="curTemplate.MessageChannelType === '微信小程序'">
        <div class="test-form-item">
          <label>小程序OpenID</label>
          <el-input v-model="toUser" placeholder="请输入小程序OpenID" />
        </div>
      </div>
      <div v-else-if="curTemplate.MessageChannelType === '企业微信'">
        <div class="test-form-item" v-if="curTemplate.chat_type === 'single' ">
          <label>外部联系人ID</label>
          <el-input v-model="toUser" placeholder="请输入外部联系人ID" />
        </div>
        <div class="test-form-item" v-if="curTemplate.chat_type === 'moment' ">
          <label>执行者ID</label>
          <el-input v-model="toUser" placeholder="请输入执行者ID" />
        </div>
        <div
          class="test-form-item"
          v-if="['group', 'employee'].indexOf(curTemplate.chat_type) > -1"
        >
          <label>内部成员ID</label>
          <el-input v-model="toUser" placeholder="请输入内部成员ID" />
        </div>
      </div>

      <div v-else-if="curTemplate.MessageChannelType === 'APP'">
        <div
          class="test-form-item"
          v-if="curTemplate.audience_id_type === 'alias'"
        >
          <label>APP别名</label>
          <el-input v-model="toUser" placeholder="请输入APP别名" />
        </div>
        <div
          class="test-form-item"
          v-if="curTemplate.audience_id_type === 'registration_id'"
        >
          <label>App注册ID</label>
          <el-input v-model="toUser" placeholder="请输入App注册ID" />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="testConfirmShow = false" type="text"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleTest" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";

import { apiGetMessageTemplatePermission, apiGetUserList } from "@/api/auth";
import {
  apiGetTemplateList,
  apiDeleteTemplate,
  apiTestTemplate,
} from "@/api/template";

export default {
  components: {
    EditDrawer,
    ContentHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      summary: [],
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      testConfirmShow: false,
      editType: "add",
      MessageChannelType: "",
      curTemplate: {},
      toUser: "",
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      showQuery: false,
      userLoading: true,
      userList: [],
      formData: {
        Name: "",
        CreatedById: "",
        MessageChannel: "",
        CreateTimeLt: "",
        CreateTimeRt: "",
      },
      channelList: [],
    };
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.status === "add") {
        this.existDrawer = true;
        this.editType = "add";
      }
    },
    testConfirmShow(val) {
      if (val === false) {
        this.toUser = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      allChannels: "common/allChannels",
      menuPermission: "common/menuPermission",
    }),
    channelActiveList() {
      const channels = this.allChannels;
      const list = [];
      channels.forEach((item) => {
        if (item.IsActive == 0) {
          return;
        }
        list.push(item);
      });
      return list;
    },
  },
  methods: {
    ...mapActions({
      getAllChannels: "common/getAllChannels",
    }),
    getUserList() {
      this.userLoading = true;
      const param = {
        offset: 0,
        limit: 1000,
      };
      apiGetUserList(param)
        .then((res) => {
          this.userList = res.users;
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    getTemplateList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      if (formData.StartTime) {
        param.CreateTimeLt = formData.StartTime[0] + ' 00:00:00';
        param.CreateTimeRt = formData.StartTime[1] + ' 23:59:59';
        delete param.StartTime;
      }

      apiGetTemplateList(param)
        .then((res) => {
          this.tableData = res.templates;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEdit(detail) {
      // 请求编辑权限
      apiGetMessageTemplatePermission(detail._id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.curTemplate = detail;
          this.editType = "edit";
          this.MessageChannelType = this.curTemplate.MessageChannelType;
          this.existDrawer = true;
        } else {
          this.curTemplate = detail;
          this.editType = "show";
          this.MessageChannelType = this.curTemplate.MessageChannelType;
          this.existDrawer = true;
        }
      });
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "delete":
          this.curTemplate = detail;
          this.delConfirmShow = true;
          break;
        case "test":
          this.curTemplate = detail;
          this.testConfirmShow = true;
          break;
      }
    },
    handleSaved() {
      this.existDrawer = false;
      this.getTemplateList();
    },
    handleDelete() {
      apiDeleteTemplate(this.curTemplate._id).then(() => {
        this.delConfirmShow = false;
        this.getTemplateList();
      });
    },
    handleTest() {
      const param = {
        toUser: this.toUser,
      };

      if (this.curTemplate.MessageChannelType !== "企业微信") {
        if (!param.toUser) {
          return;
        }
      }
      apiTestTemplate(this.curTemplate._id, param).then(() => {
        this.$message({
          message: "测试成功",
          type: "success",
        });
      });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getTemplateList();
    },
    handleQuery() {
      this.handlePage(1);
    },
  },
  mounted() {
    this.getTemplateList();
    this.getUserList();
    this.getAllChannels();
    const query = this.$route.query;
    if (query.status === "add") {
      this.existDrawer = true;
      this.editType = "add";
    }
    if (query.status) {
      this.curChannel = {
        _id: query.id,
      };
      this.editType = query.status;
      this.existDrawer = true;
    }
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .query-form {
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 30px 90px 6px 16px;
    margin: 0 0 20px 0;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 10px;
      bottom: 16px;
      background: #4d4b58;
    }
  }

  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
  .summary {
    display: flex;
    .item {
      margin-right: 76px;
      text-align: center;
    }
    .label {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-bottom: 4px;
      padding: 0 7px;
      background: #d7dcdf;
      border-radius: 20px;
    }
    .value {
      font-family: Noto Sans Display;
      font-size: 48px;
      font-weight: 300;
      line-height: 56px;
      color: #747378;
    }
  }
  .test-form-item {
    display: flex;
    line-height: 40px;
    padding: 0 16px;
    > label {
      flex: 0 0 auto;
      padding-right: 10px;
      text-align: right;
    }
    > div {
      flex: 1 1 auto;
    }
  }
  .headOperation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .showQuery {
      cursor: pointer;
    }
  }
}
</style>
