<!-- 企业 -->
<template>
  <div>
    <!-- <div style="margin-bottom: 13px">
      <el-select
        @change="handleFieldChange"
        :value="field"
        placeholder="请选择需要插入的字段"
        style="width: 214px"
      >
        <el-option
          v-for="item in fields"
          :key="item.APIName"
          :label="item.Name"
          :value="item.APIName"
        ></el-option>
      </el-select>
      <span class="textTip">插入所选字段到下面的消息内容中</span>
    </div> -->
    <div class="inputBox">
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="content"
        maxlength="255"
        show-word-limit
        rows="20"
        @change="send"
      />
      <div class="add-message-list">
        <div
          v-for="(item, index) in sendContent"
          :key="item.index"
          class="add-message-list-item"
        >
          <template v-if="item.type === 'image'">
            <img src="@/assets/svg/imgIcon.svg" />
            {{ item.title }}
          </template>
          <template v-if="item.type === 'mApp'">
            <img src="@/assets/svg/mApp.svg" />
            {{ item.title }}
          </template>
          <template v-if="item.type === 'imgText'">
            <img src="@/assets/svg/imgText.svg" />
            {{ item.title }}
          </template>
          <template v-if="item.type === 'video'">
            <img src="@/assets/svg/videoIcon.svg" />
            {{ item.title }}
          </template>

          <div @click="remove(index)">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="add-message-btn" v-if="Attachments.length < AttachMax">
        <img
          src="@/assets/svg/imgIcon.svg"
          alt=""
          @click="imageVisible = true"
          v-if="!sendContent || !sendContent.length ||(sendContent.length < 9 && sendContent[0].type=='image') || chatType !== 'moment'"
        />
        <img
          src="@/assets/svg/imgText.svg"
          alt=""
          @click="imgTextVisible = true"
          v-if="!sendContent || !sendContent.length || chatType !== 'moment'"
        />
        <img
          src="@/assets/svg/mApp.svg"
          alt=""
          @click="mAppVisible = true"
          v-if="chatType !== 'moment'"
        />
        <img
          src="@/assets/svg/videoIcon.svg"
          alt=""
          @click="videoVisible = true"
          v-if="!sendContent || !sendContent.length || chatType !== 'moment'"
        />
      </div>
    </div>

    <el-dialog
      title="添加图片"
      :visible.sync="imageVisible"
      width="640px"
      append-to-body
    >
      <el-form
        :model="imageRuleForm"
        :rules="imageRules"
        ref="imageRuleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="图片" prop="resource_url" style="margin-bottom: 0">
          <upload
            @file="imagePicurl"
            :url="imageRuleForm.length"
            :propBeforeUpload="
              (file) => {
                this.beforeUpload(file, 'image');
              }
            "
            :uploadText="'图片'"
            :ruleStr="'支持jpg、png格式，最大不超过10M，朋友圈类型图片不超过1440x1080'"
            multiple
            accept=".jpg, .png, .jpeg"
            :limit="9 - Attachments.length"
            :isMoments="chatType === 'moment'"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false" type="text">取 消</el-button>
        <el-button type="primary" round @click="confirmImage('imageRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="添加视频"
      :visible.sync="videoVisible"
      width="640px"
      append-to-body
    >
      <el-form
        :model="videoRuleForm"
        :rules="videoRules"
        ref="videoRuleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="视频" prop="resource_url" style="margin-bottom: 0">
          <upload
            @file="videoPicurl"
            :url="videoRuleForm.resource_url"
            :type="'video'"
            :propBeforeUpload="
              (file) => {
                this.beforeUpload(file, 'video');
              }
            "
            :uploadText="'视频'"
            :ruleStr="'支持MP4格式，最大不超过10M，朋友圈类型视频时长不超过30秒'"
            accept=".mp4"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoVisible = false" type="text">取 消</el-button>
        <el-button type="primary" round @click="confirmVideo('videoRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="添加图文消息"
      :visible.sync="imgTextVisible"
      width="640px"
      append-to-body
    >
      <el-form
        :model="imgTextRuleForm"
        :rules="imgTextRules"
        ref="imgTextRuleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="imgTextRuleForm.title"
            maxlength="128"
            placeholder="请输入标题，最长不超过128字节"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面及摘要" prop="picurl">
          <upload
            @file="imgTextPicurl"
            :url="imgTextRuleForm.picurl"
            :propBeforeUpload="
              (file) => {
                this.beforeUpload(file, 'image');
              }
            "
            :uploadText="'图片'"
            :ruleStr="'支持jpg、png格式，最大不超过10M，朋友圈类型图片不超过1440x1080'"
            accept=".jpg, .png, .jpeg"
            :isMoments="chatType === 'moment'"
          />
        </el-form-item>
        <el-form-item label="" prop="desc">
          <el-input
            type="textarea"
            placeholder="请输入摘要描述，最长不超过255字节"
            v-model="imgTextRuleForm.desc"
            maxlength="255"
            show-word-limit
            rows="8"
          />
        </el-form-item>
        <el-form-item label="链接" prop="url" style="margin-bottom: 0">
          <el-input
            v-model="imgTextRuleForm.url"
            maxlength="2048"
            placeholder="请输入链接，最长不超过2048字节
"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="imgTextVisible = false" type="text">取 消</el-button>
        <el-button
          type="primary"
          round
          @click="confirmImgText('imgTextRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="添加小程序"
      :visible.sync="mAppVisible"
      width="640px"
      append-to-body
    >
      <el-form
        :model="mAppRuleForm"
        :rules="mAppRules"
        ref="mAppRuleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="APPID" prop="appid">
          <el-input
            v-model="mAppRuleForm.appid"
            style="font-size: 12px"
            placeholder="请输入小程序APPID（可以在微信公众平台上查询），小程序必须关联到企业微信"
          ></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="mAppRuleForm.title"
            maxlength="64"
            style="font-size: 12px"
            placeholder="请输入标题，最长不超过64字节"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="picurl">
          <upload
            @file="mAppPicurl"
            :propBeforeUpload="
              (file) => {
                this.beforeUpload(file, 'image');
              }
            "
            :url="mAppRuleForm.picurl"
            :uploadText="'图片'"
            :ruleStr="'支持png、jpg格式，最大不超过10M，建议尺寸为520*416'"
            accept=".jpg, .png, .jpeg"
          />
        </el-form-item>
        <el-form-item label="功能页面" prop="page" style="margin-bottom: 0px">
          <el-input
            v-model="mAppRuleForm.page"
            style="font-size: 12px"
            placeholder="请输入小程序点击后跳转的功能页面地址"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="mAppVisible = false" type="text">取 消</el-button>
        <el-button type="primary" round @click="confirmMApp('mAppRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
import { cloneDeep } from "lodash";

import { getImgUrl } from "@/api/upload";

export default {
  components: {
    upload,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    // 编辑时候传入默认附件列表
    defaultAttachments: {
      type: Array,
      default: () => [],
    },
    // 编辑时候传入默认文字消息
    defaultContent: {},
    chatType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      field: "",
      content: "",
      // 图片
      imageVisible: false,
      imageRuleForm: [],
      // {
      //   resource_url: "",
      //   title: "",
      //   img_url: "",
      // },
      imageRules: {
        resource_url: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
      },
      // 视频
      videoVisible: false,
      videoRuleForm: {
        resource_url: "",
        title: "",
      },
      videoRules: {
        resource_url: [
          { required: true, message: "请上传视频", trigger: "blur" },
        ],
      },
      // 图文
      imgTextVisible: false,
      imgTextRuleForm: {
        title: "",
        url: "",
        picurl: "",
        desc: "",
        img_url: "",
      },
      imgTextRules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        url: [{ required: true, message: "请上传封面", trigger: "blur" }],
        picurl: [{ required: true, message: "请输入链接", trigger: "blur" }],
        desc: [{ required: true, message: "请输入摘要", trigger: "blur" }],
      },
      // 小程序
      mAppVisible: false,
      mAppRuleForm: {
        appid: "",
        title: "",
        picurl: "",
        page: "",
        img_url: "",
      },
      mAppRules: {
        appid: [{ required: true, message: "请输入APPID", trigger: "blur" }],
        picurl: [{ required: true, message: "请上传封面", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        page: [
          {
            required: true,
            message: " ",
            trigger: "blur",
          },
        ],
      },
      sendContent: [], // 发送的内容
      Attachments: [],
      AttachMax: 9,
    };
  },
  watch: {
    defaultAttachments(newValArr) {
      this.Attachments = cloneDeep(this.defaultAttachments);
      if (newValArr && newValArr.length) {
        // 编辑时候初始化附件列表
        newValArr.forEach((item) => {
          const fn = (img_url) => {
            if (item.AttachmentType === "image") {
              this.sendContent.push({
                resource_url: item.resource_url,
                type: "image",
                title: item.title,
                index: Math.floor(Math.random() * 100000000),
                img_url: img_url,
              });
            }
            if (item.AttachmentType === "link") {
              this.sendContent.push({
                title: item.title,
                url: item.url,
                picurl: item.picurl,
                desc: item.desc,
                type: "imgText",
                index: Math.floor(Math.random() * 100000000),
                img_url: img_url,
              });
            }
            if (item.AttachmentType === "miniprogram") {
              this.sendContent.push({
                type: "mApp",
                appid: item.appid,
                title: item.title,
                picurl: item.resource_url,
                page: item.page,
                index: Math.floor(Math.random() * 100000000),
                img_url: img_url,
              });
            }
            this.send();
          };
          if (item.AttachmentType !== "video") {
            if (!item.img_url) {
              getImgUrl(item.resource_url || item.picurl).then((res) => {
                const img_url = res.signUrl;
                fn(img_url);
                this.send();
              });
            } else {
              fn(item.img_url);
              this.send();
            }
          } else {
            this.sendContent.push({
              resource_url: item.resource_url,
              type: "video",
              title: item.title,
              index: Math.floor(Math.random() * 100000000),
            });
            this.send();
          }
        });
      }
    },
    defaultContent(newVal) {
      if (newVal) {
        // 编辑时候初始化输入框内容消息
        this.content = newVal;
        this.send();
      }
    },
  },
  methods: {
    imagePicurl(file) {
      // this.imageRuleForm.resource_url = file.url;
      // this.imageRuleForm.title = file.name;
      // this.imageRuleForm.img_url = file.img_url;
      this.imageRuleForm.push({
        resource_url: file.url,
        title: file.name,
        img_url: file.url,
      });
    },
    videoPicurl(file) {
      this.videoRuleForm.resource_url = file.url;
      this.videoRuleForm.title = file.name;
    },
    imgTextPicurl(file) {
      this.imgTextRuleForm.picurl = file.url;
      this.imgTextRuleForm.img_url = file.img_url;
    },
    mAppPicurl(file) {
      this.mAppRuleForm.picurl = file.url;
      this.mAppRuleForm.img_url = file.img_url;
    },
    handleFieldChange(value) {
      this.field = value;
      this.content += "{{" + this.field + "}}\n";
      this.send();
    },

    getFormData() {
      const data = {
        TemplateParam: this.content,
        Attachments: this.Attachments,
      };
      return data;
    },

    // 图文消息确定
    confirmImgText(formName) {
      if (this.Attachments.length >= this.AttachMax) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.imgTextRuleForm);
          this.sendContent.push({
            ...this.imgTextRuleForm,
            type: "imgText",
            index: Math.floor(Math.random() * 100000000),
          });
          this.Attachments.push({
            AttachmentType: "link",
            title: this.imgTextRuleForm.title,
            url: this.imgTextRuleForm.url,
            picurl: this.imgTextRuleForm.picurl,
            desc: this.imgTextRuleForm.desc,
          });
          this.imgTextVisible = false;
          this.imgTextRuleForm = {
            title: "",
            url: "",
            picurl: "",
            desc: "",
            img_url: "",
          };
          this.send();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 小程序确定
    confirmMApp(formName) {
      if (this.Attachments.length >= this.AttachMax) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendContent.push({
            ...this.mAppRuleForm,
            type: "mApp",
            index: Math.floor(Math.random() * 100000000),
          });
          this.Attachments.push({
            AttachmentType: "miniprogram",
            title: this.mAppRuleForm.title,
            appid: this.mAppRuleForm.appid,
            page: this.mAppRuleForm.page,
            resource_url: this.mAppRuleForm.picurl,
          });
          this.mAppVisible = false;
          this.mAppRuleForm = {
            appid: "",
            title: "",
            picurl: "",
            page: "",
            img_url: "",
          };
          this.send();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传视频确定
    confirmVideo() {
      if (this.Attachments.length >= this.AttachMax) {
        return;
      }
      this.sendContent.push({
        ...this.videoRuleForm,
        type: "video",
        index: Math.floor(Math.random() * 100000000),
      });
      this.Attachments.push({
        AttachmentType: "video",
        resource_url: this.videoRuleForm.resource_url,
        title: this.videoRuleForm.title,
      });
      this.send();
      this.videoVisible = false;
      this.videoRuleForm = {
        resource_url: "",
      };
    },
    // 上传图片信息确定
    confirmImage() {
      if (this.Attachments.length >= this.AttachMax) {
        return;
      }

      this.imageRuleForm.forEach((item) => {
        this.sendContent.push({
          ...item,
          type: "image",
          index: Math.floor(Math.random() * 100000000),
        });
        this.Attachments.push({
          AttachmentType: "image",
          resource_url: item.resource_url,
          title: item.title,
        });
      });
      this.send();
      this.imageVisible = false;
      this.imageRuleForm = [];
    },

    remove(index) {
      this.sendContent.splice(index, 1);
      this.Attachments.splice(index, 1);
      this.send();
    },
    send() {
      var content = [];
      if (this.content !== "") {
        content.push({
          content: this.content,
          type: "string",
          index: Math.floor(Math.random() * 100000000),
        });
      }
      this.$emit("sendWxMessage", [...content, ...this.sendContent]);
    },
    beforeUpload(file, attachmentType) {
      var isType = true,
        isLt,
        lt;
      if (attachmentType === "image") {
        isType = ["image/png", "image/jpeg"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }
      if (attachmentType === "voice") {
        isType =
          ["audio/amr"].indexOf(file.type) > -1 || file.name.endsWith("amr");
        isLt = file.size / 1024 / 1024 < 2;
        lt = 2;
      }
      if (attachmentType === "video") {
        isType = ["video/mp4"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }

      if (!isType) {
        this.$message.error(`文件格式不正确 ${file.type}`);
      }
      if (!isLt) {
        this.$message.error(`上传文件大小不能超过 ${lt}MB!`);
      }
      // 如果不符合条件
      if (!isType || !isLt) {
        return new Promise.reject();
      }

      return true;
    },
  },
  created() {
    console.log(this.defaultAttachments);
  },
};
</script>

<style lang="less" scoped>
.textTip {
  color: #0483ec;
  margin-left: 20px;
  font-size: 12px;
}

.inputBox {
  width: 465px;
}

.add-message-list {
  background: #f6f7f8;
  padding: 4px 14px;
  &-item {
    display: flex;
    align-items: center;
    line-height: 20px;
    word-break: break-all;
    img {
      margin-right: 10px;
      width: 12px;
    }
    .el-icon-close {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
.add-message-btn {
  background: #f6f7f8;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  height: 40px;
  padding-left: 14px;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-right: 20px;
  }
}

.upload-demo {
  // margin-bottom: 20px;
}
/deep/ .el-upload {
  display: block;
}
/deep/ .el-upload-dragger {
  width: 100%;
}

/deep/ .el-form {
  .el-form-item {
    margin-bottom: 24px;
  }
}
</style>
