<template>
  <div class="box">
    <div class="content">
      <div class="title">模板内容：</div>
      <pre class="inner">{{ content }}</pre>
    </div>
    <div class="content">
      <div class="title">消息示例：</div>
      <pre class="inner">{{ example }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
    },
    example: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: row;

  .content {
    flex: 1;
    padding: 20px 20px 16px;

    background: #f6f7f8;
    border-radius: 6px;
    color: #747378;
    margin-right: 20px;

    .title {
      font-size: 12px;
      margin-bottom: 16px;
    }
    .inner {
      font-size: 14px;
      line-height: 22px;
      word-break: break-all; // 按字符截断换行 /* 支持IE和chrome，FF不支持*/
      word-wrap: break-word; // 按英文单词整体截断换行  /* 以上三个浏览器均支持 */
      white-space: pre-wrap;
    }
  }
}
</style>
