<template>
  <el-form
    :model="formData"
    :rules="formRule"
    ref="form"
    class="edit-form"
    size="small"
    label-width="100px"
  >
    <el-form-item label="推送标题" prop="title">
      <div style="margin-bottom: 13px">
        <el-select
          @change="handleTitleFieldChange"
          :value="titleField"
          placeholder="请选择需要插入的字段"
          style="width: 214px"
        >
          <el-option
            v-for="item in fields"
            :key="item.APIName"
            :label="item.Name"
            :value="item.APIName"
          ></el-option>
        </el-select>

        <span class="textTip">插入所选字段到下面的消息内容中</span>
      </div>
      <el-input
        type="textarea"
        placeholder="请输入标题"
        v-model="formData.title"
        maxlength="4000"
        show-word-limit
        rows="10"
        @change="send"
        class="inputBox"
      />
    </el-form-item>

    <el-form-item label="推送内容" prop="content">
      <div style="margin-bottom: 13px">
        <el-select
          @change="handleContentFieldChange"
          :value="contentField"
          placeholder="请选择需要插入的字段"
          style="width: 214px"
        >
          <el-option
            v-for="item in fields"
            :key="item.APIName"
            :label="item.Name"
            :value="item.APIName"
          ></el-option>
        </el-select>

        <span class="textTip">插入所选字段到下面的消息内容中</span>
      </div>
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="formData.content"
        maxlength="4000"
        show-word-limit
        rows="10"
        @change="send"
        class="inputBox"
      />
    </el-form-item>

    <el-form-item label="点击推送后" prop="RedirectOption">
      <el-radio-group v-model="formData.RedirectOption">
        <el-radio label="唤醒应用">唤醒应用</el-radio>
        <el-radio label="跳转到指定链接">跳转到指定链接</el-radio>
        <el-radio label="跳转到自定义链接">跳转到自定义链接</el-radio>
      </el-radio-group>
      <div
        style="width: 100%; height: 8px"
        v-if="formData.RedirectOption !== '唤醒应用'"
      ></div>
      <el-form-item
        label=""
        prop="RedirectValue"
        v-if="formData.RedirectOption === '跳转到指定链接'"
      >
        <el-input
          placeholder="请输入以http://或https://开头的URL地址"
          v-model="formData.RedirectValue"
        ></el-input>
      </el-form-item>

      <div
        class="custom-link"
        v-if="formData.RedirectOption === '跳转到自定义链接'"
      >
        <div class="custom-link-title">参数:</div>
        <template v-for="(item, index) in customLink">
          <el-form-item :label="`${item.key}`" label-width="60px" :key="index">
            <div style="margin-bottom: 13px">
              <el-select
                @change="(val) => handleCustomFieldChange(val, index)"
                v-model="item.field"
                placeholder="请选择需要插入的字段"
                style="width: 214px"
              >
                <el-option
                  v-for="item in fields"
                  :key="item.APIName"
                  :label="item.Name"
                  :value="item.APIName"
                ></el-option>
              </el-select>

              <span class="textTip">插入所选字段到下面的消息内容中</span>
            </div>
            <el-input
              type="textarea"
              :placeholder="item.placeholder"
              v-model="item.content"
              rows="3"
              @change="send"
            />
          </el-form-item>
          <el-divider :key="index"></el-divider>
        </template>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    // app某个渠道自定义连接key, 跳转到自定义连接需要
    appKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    var validateRedirectValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("链接不能为空"));
      }
      var reg = /(http\:\/\/|https\:\/\/)(.*?)/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的链接"));
      }
    };
    return {
      formData: {
        title: "",
        content: "",
        RedirectValue: "", // 跳转到指定链接的值
        RedirectOption: "唤醒应用",
      },
      formRule: {
        RedirectValue: {
          validator: validateRedirectValue,
          trigger: "blur",
        },
        content: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
        title: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      },
      titleField: "",
      contentField: "",
      customLink: [
        // 自定义连接key  {field: '选择变量', placeholder: 'xx', content: '', key: '参数1'}, 跳转到自定义连接需要
      ],
    };
  },
  watch: {
    appKeys(newVal) {
      if (newVal) {
        this.customLink = newVal;
      }
    },
  },
  methods: {
    // 注意：外部调用时候，必须保证 customLink 有值了，即appKeys已经获取到了
    setDefaultValue(formData) {
      if (formData) {
        this.formData.RedirectOption = formData.RedirectOption;
        this.formData.content = formData.TemplateParam;
        this.formData.title = formData.MessageTitle;
        if (
          this.formData.RedirectOption === "跳转到自定义链接" &&
          formData.RedirectOptionValue
        ) {
          this.customLink.forEach((item) => {
            item.content = formData.RedirectOptionValue[item.key];
          });
        }
        if (this.formData.RedirectOption === "跳转到指定链接") {
          this.formData.RedirectValue = formData.RedirectOptionValue;
        }
        this.send();
      }
    },
    handleTitleFieldChange(value) {
      this.titleField = value;
      this.formData.title += "{{" + this.titleField + "}}\n";
      this.send();
    },
    handleContentFieldChange(value) {
      this.contentField = value;
      this.formData.content += "{{" + this.contentField + "}}\n";
      this.send();
    },
    // 自定义连接改变
    handleCustomFieldChange(val, index) {
      if (this.customLink[index]) {
        this.customLink[index].content += "{{" + val + "}}\n";
      }
    },
    send() {
      this.$emit("sendAppMessage", {
        content: this.formData.content,
        title: this.formData.title,
      });
    },
    getFormData() {
      let data = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          data = {
            MessageTitle: this.formData.title,
            TemplateParam: this.formData.content,
            RedirectOption: this.formData.RedirectOption,
          };
          if (data.RedirectOption === "跳转到自定义链接") {
            const RedirectOptionValue = {};
            this.customLink.forEach((item) => {
              RedirectOptionValue[item.key] = item.content;
            });
            data.RedirectOptionValue = RedirectOptionValue;
          }
          if (data.RedirectOption === "跳转到指定链接") {
            data.RedirectOptionValue = this.formData.RedirectValue;
          }
        }
      });

      return data;
    },
    resetForm() {
      this.formData = {
        title: "",
        content: "",
        RedirectValue: "", // 跳转到指定链接的值
        RedirectOption: "唤醒应用",
      };
      this.customLink = [];
      this.contentField = "";
      this.titleField = "'";
    },
  },
};
</script>

<style lang="less" scoped>
.textTip {
  color: #0483ec;
  margin-left: 20px;
  font-size: 12px;
}
.inputBox {
  width: 465px;
}

.custom-link {
  padding: 20px 14px;
  background: #f6f7f8;
  border-radius: 6px;
  &-title {
    font-size: 12px;
    color: #747378;
  }
}
</style>
