<template>
  <div>
    <Company
      :fields="fields"
      :defaultAttachments="defaultAttachments"
      :defaultContent="defaultContent"
      @sendWxMessage="sendWxMessage"
      :chatType="chatType"
      v-if="type === 'company'"
      ref="companyRef"
    />
    <Employee
      :fields="fields"
      :defaultAttachments="defaultAttachments"
      :defaultContent="defaultContent"
      @sendWxMessage="sendWxMessage"
      v-if="type === 'employee'"
      ref="employeeRef"
    />
  </div>
</template>

<script>
import Company from "./company.vue";
import Employee from "./employee.vue";

export default {
  components: {
    Company,
    Employee,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    AgentId: null,
    chatType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      defaultAttachments: [], // 编辑时候传入默认附件列表
      defaultContent: {}, // 编辑时候传入默认文字消息
      type: "company", // 类型：company , employee
    };
  },
  watch: {
    AgentId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.type = "employee";
        } else {
          this.type = "company";
        }
      },
    },
  },
  methods: {
    default(defaultAttachments, defaultContent) {
      this.defaultAttachments = defaultAttachments;
      this.defaultContent = defaultContent;
    },
    sendWxMessage(...args) {
      this.$emit("sendWxMessage", ...args);
    },
    getFormData() {
      if (this.type === "company") {
        return this.$refs.companyRef && this.$refs.companyRef.getFormData();
      }
      if (this.type === "employee") {
        return this.$refs.employeeRef && this.$refs.employeeRef.getFormData();
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
