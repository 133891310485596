<template>
  <div v-loading="loading">
    <iframe :src="url" frameborder="0" width="100%" :height="height"></iframe>
  </div>
</template>

<script>
import { apiGetyiqixiucode } from "@/api/template/index";
export default {
  data() {
    return {
      url: null,
      height: 0,
      loading: true,
    };
  },
  methods: {
    getyiqixiu() {
      this.loading = true;
      apiGetyiqixiucode()
        .then((res) => {
          this.url = `https://www.eqxiu.cn/embed/creation?secrectId=${res.secret_id}&Authorization=${res.login_code}`;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getyiqixiu();
  },
  mounted() {
    const elMain = document.getElementsByClassName("el-main")[0];
    this.height = elMain.offsetHeight - 45 + "px";
  },
};
</script>

<style lang="less" scoped></style>
