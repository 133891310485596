<!-- 内部员工 -->
<template>
  <div class="inputBox">
    <el-radio-group
      v-model="AttachmentType"
      @change="(val) => changeAttachmentType(val)"
    >
      <el-radio :label="item.id" v-for="item in typeList" :key="item.id">{{
        item.label
      }}</el-radio>
    </el-radio-group>
    <div style="margin-top: 10px">
      <div
        style="margin-bottom: 13px; margin-top: 13px"
        v-if="AttachmentType === 'content'"
      >
        <el-select
          @change="handleFieldChange"
          :value="field"
          placeholder="请选择需要插入的字段"
          style="width: 214px"
        >
          <el-option
            v-for="item in fields"
            :key="item.APIName"
            :label="item.Name"
            :value="item.APIName"
          ></el-option>
        </el-select>
        <span class="textTip">插入所选字段到下面的消息内容中</span>
      </div>
      <el-input
        style="width: 400px"
        type="textarea"
        placeholder="请输入内容，最长不超过2048字节"
        v-model="content"
        maxlength="2048"
        show-word-limit
        rows="20"
        @change="send"
        v-if="AttachmentType === 'content'"
      />
      <div v-else>
        <upload
          @file="changeUpload"
          @remove="removeUpload"
          :url="url"
          :type="AttachmentType"
          :propBeforeUpload="beforeUpload"
          :uploadText="uploadText"
          :ruleStr="ruleStr"
          ref="uploadRef"
        />
        <div class="el-form-item__error">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
import { cloneDeep } from "lodash";
import { getImgUrl } from "@/api/upload";

export default {
  components: {
    upload,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    // 编辑时候传入默认附件列表
    defaultAttachments: {
      type: Array,
      default: () => [],
    },
    // 编辑时候传入默认文字消息
    defaultContent: {},
  },
  data() {
    return {
      rule: {
        image: "支持jpg、png格式，最大不超过10M",
        voice: "支持amr格式，最大不超过2M，播放时长不可超过60s",
        video: "支持mp4格式，每个视频文件最大支持10M",
        file: "最大不超过10M",
      },
      typeList: [
        {
          label: "文字",
          id: "content",
        },
        {
          label: "图片",
          id: "image",
        },
        {
          label: "语音",
          id: "voice",
        },
        {
          label: "视频",
          id: "video",
        },
        {
          label: "文件",
          id: "file",
        },
      ],
      error: "",
      AttachmentType: "content",
      // 文字
      content: "",
      // 图片
      imageRuleForm: {
        resource_url: "",
        title: "",
      },
      // 语音
      voiceRuleForm: {
        resource_url: "",
        title: "",
      },
      // 视频
      videoRuleForm: {
        resource_url: "",
        title: "",
      },
      // 文件
      fileRuleForm: {
        resource_url: "",
        title: "",
      },
      sendContent: [], // 发送的内容
      Attachments: [],
      uploadText: "",
      ruleStr: "", // 上传格式辅助提示
      url: "", // 已经上传的文件地址
    };
  },
  watch: {
    defaultAttachments(newValArr) {
      this.Attachments = cloneDeep(this.defaultAttachments);
      if (newValArr && newValArr.length) {
        // 编辑时候初始化附件列表
        newValArr.forEach((item) => {
          const fn = (img_url) => {
            this.AttachmentType = item.AttachmentType;
            if (item.AttachmentType === "image") {
              this.sendContent = [
                {
                  resource_url: item.resource_url,
                  type: "image",
                  title: item.title,
                  index: Math.floor(Math.random() * 100000000),
                  img_url: img_url,
                },
              ];
            }
            if (item.AttachmentType === "voice") {
              this.sendContent = [
                {
                  resource_url: item.resource_url,
                  type: "voice",
                  title: item.title,
                  index: Math.floor(Math.random() * 100000000),
                  img_url: img_url,
                },
              ];
            }
            if (item.AttachmentType === "video") {
              this.sendContent = [
                {
                  resource_url: item.resource_url,
                  type: "video",
                  title: item.title,
                  index: Math.floor(Math.random() * 100000000),
                  img_url: img_url,
                },
              ];
            }
            if (item.AttachmentType === "file") {
              this.sendContent = [
                {
                  resource_url: item.resource_url,
                  type: "file",
                  title: item.title,
                  index: Math.floor(Math.random() * 100000000),
                  img_url: img_url,
                },
              ];
            }
            this.ruleStr = this.rule[this.AttachmentType];
            const find = this.typeList.find((item) => {
              return item.id === this.AttachmentType;
            });
            if (find) {
              this.uploadText = `${find.label}`;
              this.ruleStr = this.rule[this.AttachmentType];
            }

            this.url = this.sendContent[0].resource_url;
            this.$nextTick(() => {
              this.$refs.uploadRef &&
                this.$refs.uploadRef.setFileList({
                  url: this.sendContent[0].url,
                  name: this.sendContent[0].title,
                  img_url: this.sendContent[0].img_url,
                });
            });
            this.send();
          };
          if (!item.img_url) {
            getImgUrl(item.resource_url).then((res) => {
              const img_url = res.signUrl;
              fn(img_url);
            });
          } else {
            fn(item.img_url);
          }
        });
      }
    },
    defaultContent(newVal) {
      if (newVal) {
        // 编辑时候初始化输入框内容消息
        this.content = newVal;
        this.send();
      }
    },
  },
  methods: {
    beforeUpload(file) {
      var isType = true,
        isLt,
        lt;
      if (this.AttachmentType === "image") {
        isType = ["image/png", "image/jpeg"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }
      if (this.AttachmentType === "voice") {
        isType = (["audio/amr"].indexOf(file.type) > -1) || file.name.endsWith("amr");
        isLt = file.size / 1024 / 1024 < 2;
        lt = 2;
      }
      if (this.AttachmentType === "video") {
        isType = ["video/mp4"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }
      if (this.AttachmentType === "file") {
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }

      if (!isType) {
        this.$message.error(`文件格式不正确 ${file.type}`);
      }
      if (!isLt) {
        this.$message.error(`上传文件大小不能超过 ${lt}MB!`);
      }
      // 如果不符合条件
      if (!isType || !isLt) {
        return new Promise.reject();
      }

      return true;
    },
    handleFieldChange(value) {
      this.field = value;
      this.content += "{{" + this.field + "}}\n";
      this.send();
    },
    changeAttachmentType(val) {
      this.AttachmentType = val;
      this.sendContent = [
        {
          resource_url: "",
          type: val,
          title: "",
          index: Math.floor(Math.random() * 100000000),
        },
      ];
      this.Attachments = [];
      const find = this.typeList.find((item) => {
        return item.id === this.AttachmentType;
      });
      if (find && this.AttachmentType !== "content") {
        this.uploadText = `${find.label}`;
        this.ruleStr = this.rule[this.AttachmentType];
        this.url = "";
      } else {
        this.uploadText = "";
      }
      this.error = "";
    },
    send() {
      var content = [];
      if (this.content !== "") {
        content.push({
          content: this.content,
          type: "string",
          index: Math.floor(Math.random() * 100000000),
        });
      }
      this.$emit("sendWxMessage", [...content, ...this.sendContent]);
    },
    getMessageType() {
      let MessageType = "";
      if (this.AttachmentType === "content") {
        MessageType = "文本消息";
      }
      if (this.AttachmentType === "image") {
        MessageType = "图片消息";
      }
      if (this.AttachmentType === "video") {
        MessageType = "视频消息";
      }
      if (this.AttachmentType === "voice") {
        MessageType = "语音消息";
      }
      if (this.AttachmentType === "file") {
        MessageType = "文件消息";
      }
      return MessageType;
    },
    getFormData() {
      if (!this.checked()) {
        const data = {
          TemplateParam: this.content + "",
          Attachments: this.Attachments,
          MessageType: this.getMessageType(),
        };
        return data;
      }
      return null;
    },
    checked() {
      if (this.AttachmentType === "content") {
        if (!this.content) {
          this.error = "请输入文字消息";
        } else {
          this.error = "";
        }
      } else {
        if (this.sendContent.length) {
          if (!this.sendContent[0].resource_url) {
            this.error = "请选择上传附件";
          } else {
            this.error = "";
          }
        }
      }

      return this.error;
    },
    changeUpload(file) {
      if (this.sendContent.length) {
        this.sendContent[0].resource_url = file.url;
        this.sendContent[0].title = file.name;
        this.sendContent[0].img_url = file.img_url;
        this.url = file.url;
        this.Attachments.push({
          AttachmentType: this.sendContent[0].type,
          resource_url: this.sendContent[0].resource_url,
          title: this.sendContent[0].title,
          img_url: this.sendContent[0].img_url,
        });
        this.error = "";
        this.send();
      }
    },
    removeUpload() {
      this.Attachments = [];
      this.url = "";
      this.AttachmentType =
        (this.sendContent.length && this.sendContent[0].type) || "image";

      this.sendContent = [
        {
          resource_url: "",
          type: this.AttachmentType,
          title: "",
          index: Math.floor(Math.random() * 100000000),
          img_url: "",
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.textTip {
  color: #0483ec;
  margin-left: 20px;
  font-size: 12px;
}
</style>
