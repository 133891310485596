<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :size="1020"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add"
              ? "新建内容"
              : saveType === "show"
              ? "查看内容"
              : "修改内容"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content" v-loading="getDetailLoading">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="100px"
          :disabled="disabled"
        >
          <cond-card title="内容渠道">
            <div class="plan-types">
              <radio-item
                v-for="(item, index) in channelTypes"
                :key="index"
                :detail="item"
                :disabled="saveType !== 'add'"
                :active="formData.MessageChannelType === item.name"
                @change="handleTypeChange"
              ></radio-item>
            </div>
            <el-form-item label="内容渠道" prop="MessageChannel">
              <el-select
                v-model="formData.MessageChannel"
                placeholder="请选择内容渠道"
                style="width: 220px"
                filterable
                @change="(e) => changeMessageChannel(e)"
              >
                <el-option
                  v-for="item in channelList"
                  :label="item.Name"
                  :value="item._id"
                  :key="item._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </cond-card>
          <cond-card
            :title="formData.MessageChannelType + '内容'"
            style="min-height: 450px"
          >
            <el-form-item label="名称" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入内容模板名称"
                style="width: 465px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="公众号AppID"
              prop="WechatAppID"
              v-if="['微信小程序'].indexOf(formData.MessageChannelType) > -1"
            >
              <el-input
                v-model="formData.WechatAppID"
                placeholder="请输入公众号AppID"
                style="width: 465px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="内容类型"
              prop="MessageType"
              v-if="['微信公众号'].indexOf(formData.MessageChannelType) > -1"
            >
              <el-radio-group
                v-model="formData.MessageType"
                @change="(e) => changeMessageType(e)"
              >
                <el-radio label="模板消息">模板消息</el-radio>
                <el-radio label="图片消息">群发图片</el-radio>
                <el-radio label="图文消息">群发图文</el-radio>
                <el-radio label="语音消息">群发语音</el-radio>
                <el-radio label="视频消息">群发视频</el-radio>
                <el-radio label="文本消息">群发文本</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- <el-form-item
              label="内容模板ID"
              prop="TemplateId"
              v-if="
                (formData.MessageChannelType === '短信' &&
                  formData.IsTemplateSMS === true) ||
                formData.MessageChannelType === '微信小程序'
              "
            > -->
            <el-form-item
              label="内容模板ID"
              prop="TemplateId"
              v-if="
                formData.MessageChannelType === '短信' &&
                formData.IsTemplateSMS === true
              "
            >
              <el-input
                v-model="formData.TemplateId"
                placeholder="请输入内容模板ID"
                style="width: 465px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="模板名称"
              prop="TemplateId"
              v-if="
                (['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                  formData.MessageType === '模板消息') ||
                ['微信小程序'].indexOf(formData.MessageChannelType) > -1
              "
            >
              <el-select
                filterable
                v-model="formData.TemplateId"
                style="width: 465px"
                @change="(e) => changeTemplateId(e)"
              >
                <el-option
                  v-for="item in wechatMessageList || []"
                  :label="item.title"
                  :value="item.template_id"
                  :key="item.template_id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label=""
              v-if="
                (['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                  formData.MessageType === '模板消息') ||
                ['微信小程序'].indexOf(formData.MessageChannelType) > -1
              "
            >
              <WechatTemplate
                :content="wechatMessage && wechatMessage.content"
                :example="wechatMessage && wechatMessage.example"
              />
            </el-form-item>

            <el-form-item
              label="微信内容素材"
              prop="media_ids_图片消息"
              v-if="
                ['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                ['图片消息'].indexOf(formData.MessageType) > -1
              "
            >
              <el-select
                filterable
                multiple
                v-model="formData.media_ids_图片消息"
                style="width: 465px"
                v-loadmore="loadMediaMore"
              >
                <el-option
                  v-for="item in mediaMap[formData.MessageType] || []"
                  :label="item.name"
                  :value="item.media_id"
                  :key="item.media_id"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="微信内容素材"
              prop="media_ids"
              v-if="
                ['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                ['图文消息', '语音消息', '视频消息'].indexOf(
                  formData.MessageType
                ) > -1
              "
            >
              <el-select
                v-model="formData.media_ids"
                style="width: 465px"
                filterable
                v-loadmore="loadMediaMore"
              >
                <el-option
                  v-for="item in mediaMap[formData.MessageType] || []"
                  :label="item.name"
                  :value="item.media_id"
                  :key="item.media_id"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="微信素材"
              prop="TemplateParam_文本消息"
              v-if="
                ['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                formData.MessageType === '文本消息'
              "
            >
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="formData.TemplateParam_文本消息"
                maxlength="512"
                show-word-limit
                rows="5"
              />
            </el-form-item>

            <TextMessage
              :fields="fieldList"
              :SMSChannelType="SMSChannelType"
              v-if="
                formData.MessageChannelType === '短信' &&
                formData.IsTemplateSMS === false
              "
              ref="textMessageRef"
            />
            <el-form-item
              label="内容变量"
              class="contentVariable"
              v-if="
                (formData.MessageChannelType === '短信' &&
                  formData.IsTemplateSMS === true) ||
                formData.MessageChannelType === '微信小程序' ||
                (['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                  formData.MessageType === '模板消息')
              "
            >
              <cond-item-wrap
                :showPlaceholder="false"
                :isEmpty="formData.TemplateParam.length === 0"
              >
                <cond-item
                  v-for="(item, index) in formData.TemplateParam"
                  :key="item.id"
                  :detail="item"
                  :index="index"
                  :fields="fieldList"
                  @remove="handleRemoveCondCust"
                ></cond-item>
                <div
                  v-if="
                    !(
                      (['微信公众号'].indexOf(formData.MessageChannelType) >
                        -1 &&
                        formData.MessageType === '模板消息') ||
                      formData.MessageChannelType === '微信小程序'
                    )
                  "
                >
                  <el-button type="text" @click="handleAddCondCust"
                    >+ 添加变量</el-button
                  >
                </div>
              </cond-item-wrap>
            </el-form-item>

            <el-form-item
              label="跳转链接"
              prop="RedirectURL"
              v-if="
                formData.MessageChannelType === '微信小程序' ||
                (['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                  formData.MessageType === '模板消息')
              "
            >
              <el-input
                v-model="formData.RedirectURL"
                placeholder="请输入跳转链接"
                style="width: 465px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="小程序跳转页"
              prop="MiniProgramRedirectPage"
              v-if="['微信小程序'].indexOf(formData.MessageChannelType) > -1"
            >
              <el-input
                v-model="formData.MiniProgramRedirectPage"
                placeholder="请输入小程序默认跳转页的地址"
                style="width: 465px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="跳转小程序"
              prop="RelatedAppletAppId"
              v-if="
                ['微信公众号'].indexOf(formData.MessageChannelType) > -1 &&
                formData.MessageType === '模板消息'
              "
            >
              <el-input
                v-model="formData.RelatedAppletAppId"
                placeholder="请输入小程序APPID(可以在微信公众号平台查询)，小程序必须关联到微信公众号"
                style="width: 465px; margin-bottom: 5px"
              ></el-input>
              <el-input
                v-model="formData.RelatedAppletAppUrl"
                placeholder="请输入小程序跳转路径，如page/page/index"
                style="width: 465px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="群发类型"
              prop="chat_type"
              v-if="formData.MessageChannelType === '企业微信' && !AgentId"
            >
              <el-select v-model="formData.chat_type" style="width: 465px">
                <el-option
                  v-for="item in picklist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="消息内容"
              v-if="formData.MessageChannelType === '企业微信'"
            >
              <CompanyWx
                :fields="fieldList"
                :defaultAttachments="formData.Attachments"
                :defaultContent="formData.TemplateParam"
                :AgentId="AgentId"
                @sendWxMessage="sendWxMessage"
                :chatType="formData.chat_type"
                ref="companyWxRef"
              />
            </el-form-item>
            <el-form-item
              label="分享设置"
              prop="safe"
              v-if="formData.MessageChannelType === '企业微信' && AgentId"
            >
              <el-checkbox v-model="formData.safe"
                >不能分享且封面内容显示水印</el-checkbox
              >
            </el-form-item>
            <APP
              :appKeys="appKeys"
              :fields="fieldList"
              v-if="formData.MessageChannelType === 'APP'"
              @sendAppMessage="sendAppMessage"
              ref="appRef"
            />
            <el-form-item label="内容标签" prop="MessageTags">
              <add-tags :list="formData.MessageTags"></add-tags>
            </el-form-item>

            <CompanyTemplate
              :conpanyWxSendContent="conpanyWxSendContent"
              class="companyTemplate"
              v-if="formData.MessageChannelType === '企业微信' && formData.chat_type !== 'moment' "
            />
            <AppTemplate
              :appSendContent="appSendContent"
              class="companyTemplate"
              v-if="formData.MessageChannelType === 'APP'"
              :name="formData.Name"
            />
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  apiAddTemplate,
  apiUpdateTemplate,
  apiGetTemplateDetail,
  apiGetWechatMessageList,
} from "@/api/template";
import util from "@/util";
import condCard from "@/components/cond-card";
import radioItem from "@/components/radio-item";
import addTags from "@/components/add-tags";
import condItemWrap from "@/components/cond-item-wrap";
import condItem from "./cond-item";
import { apiGetChannelListByType, apiGetChannelDetail } from "@/api/channel";
import { apiGetMediaList } from "@/api/template/index";

import CompanyWx from "./company-wx/index.vue";
import CompanyTemplate from "./company-template.vue";
import { cloneDeep } from "lodash";
import APP from "./APP";
import AppTemplate from "./app-template.vue";
import TextMessage from "./text-message/index.vue";
import WechatTemplate from "./wechat-template.vue";

export default {
  components: {
    condCard,
    radioItem,
    addTags,
    condItemWrap,
    condItem,
    CompanyWx,
    CompanyTemplate,
    APP,
    AppTemplate,
    TextMessage,
    WechatTemplate,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    MessageChannelType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      getDetailLoading: false,
      saving: false,
      direction: "rtl",
      AgentId: "", // 企业微信，内容渠道的 AgentId 值
      SMSChannelType: "", //短信type
      formData: {
        Name: "",
        MessageChannelType: "短信",
        MessageChannel: "",
        MessageType: "模板消息",
        TemplateId: "",
        TemplateParam: [],
        RedirectURL: "",
        MessageTags: [],
        Attachments: [],
        MiniProgramRedirectPage: "",
        WechatAppID: "",
        media_ids: "",
        MessageTitle: "",
        RedirectOption: "",
        RedirectOptionValue: "",
        chat_type: "",
        media_ids_图片消息: [],
        TemplateParam_文本消息: "",
        safe: false,
        IsTemplateSMS: null,
        RelatedAppletAppId: "",
        RelatedAppletAppUrl: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入内容模板名称",
          trigger: "blur",
        },
        MessageChannel: {
          required: true,
          message: "请选择内容渠道",
          trigger: "change",
        },
        TemplateId: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
        RedirectURL: {
          required: false,
          message: "请输入跳转链接",
          trigger: "blur",
        },
        MiniProgramRedirectPage: {
          required: false,
          message: "请输入小程序默认跳转页的地址",
          trigger: "blur",
        },
        // WechatAppID: {
        //   required: true,
        //   message: "请输入公众号AppID",
        //   trigger: "blur",
        // },
        media_ids: {
          required: true,
          message: "请选择微信内容素材",
          trigger: "change",
        },
        media_ids_图片消息: {
          required: true,
          message: "请选择微信内容素材",
          trigger: "change",
        },
        TemplateParam_文本消息: {
          required: true,
          message: "请输入文本消息",
          trigger: "blur",
        },
        chat_type: {
          required: true,
          message: "请选择群发类型",
          trigger: "change",
        },
      },
      channelTypes: [
        {
          icon: "el-icon-time",
          name: "短信",
        },
        {
          icon: "el-icon-date",
          name: "微信公众号",
        },
        {
          icon: "el-icon-date",
          name: "企业微信",
        },
        // {
        //   icon: "el-icon-date",
        //   name: "微信小程序",
        // },
        // {
        //   icon: "el-icon-date",
        //   name: "APP",
        // },
      ],
      dxChannels: [],
      gzhChannels: [],
      companyWxChannels: [],
      conpanyWxSendContent: [],
      miniProgramChannels: [],
      appChannels: [],
      appSendContent: {},
      mediaMap: {
        图文消息: [],
        视频消息: [],
        语音消息: [],
        图片消息: [],
      },
      appKeys: [], // 某个app渠道自定义字段
      disabled: false,
      wechatMessageList: [], // 微信公众号消息模板列表
      metaPagination: {
        // 公众号素材列表请求分页参数
        offset: 0,
        limit: 20,
      },
      metaMore: true, // 是否还需要滚动,已经没有新数据了,默认有
      metaLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
      customerBehaFields: "common/customerBehaFields",
      messageTemplate: "common/messageTemplate",
    }),
    fieldList() {
      return [...this.customerFields, ...this.customerBehaFields];
    },
    channelList() {
      if (this.formData.MessageChannelType === "短信") {
        return this.dxChannels;
      }
      if (this.formData.MessageChannelType === "企业微信") {
        return this.companyWxChannels;
      }
      if (this.formData.MessageChannelType === "微信小程序") {
        return this.miniProgramChannels;
      }
      if (this.formData.MessageChannelType === "APP") {
        return this.appChannels;
      }
      return this.gzhChannels;
    },
    field() {
      const name = "chat_type";
      const field = this.messageTemplate.find((item) => item.APIName === name);
      return field || {};
    },
    picklist() {
      if (this.field) {
        const field = this.field.PicklistOptions || [];
        const arr = [];
        field.forEach((val) => {
          if (val == "single") {
            arr.push({
              label: "单独发送给客户",
              value: val,
            });
          } else if (val == "group") {
            // arr.push({
            //   label: "发送到客户所在群",
            //   value: val,
            // });
          } 
          else if (val == "moment") {
            arr.push({
              label: "发送到客户朋友圈",
              value: val,
            });
          } 
          else {
            arr.push({
              label: val,
              value: val,
            });
          }
        });
        return arr;
      } else {
        return [];
      }
    },
    // 微信公众号-选择模板消息-选择的模板对象
    wechatMessage() {
      const find = this.wechatMessageList.find((item) => {
        return item.template_id === this.formData.TemplateId;
      });
      return find;
    },
  },
  // watch: {
  //   exist(newVal) {
  //     if (newVal === true) {
  //       this.getTemplateDetail();
  //     } else {
  //       this.resetForm();
  //     }
  //   },
  // },
  methods: {
    handleIsTemplateSMS(val) {
      if (val) {
        this.formData.TemplateParam = [];
      } else {
        this.formData.TemplateParam = "";
      }
    },
    // 微信公众号-》模板消息-》选择模板名称触发-》确定变量列表
    changeTemplateId(value) {
      const find = this.wechatMessageList.find((item) => {
        return item.template_id === value;
      });
      if (find) {
        this.formData.TemplateParam = find.TemplateParam || [];
      }
    },
    changeMessageChannel(value, isFirst) {
      if (this.formData.MessageChannelType === "微信公众号") {
        this.changeMessageType(this.formData.MessageType, isFirst);
      }
      if (this.formData.MessageChannelType === "微信小程序") {
        const channelId = this.formData.MessageChannel;
        apiGetChannelDetail(channelId).then((res) => {
          this.getWechatMessageList(isFirst, res.RelatedWechatAppIds);
        });
      }
      if (this.formData.MessageChannelType === "APP") {
        if (value) {
          this.getAppChannelFields(value, isFirst);
        }
      }
      if (this.formData.MessageChannelType === "短信") {
        this.dxChannels.forEach((item) => {
          if (item._id === value) {
            this.formData.IsTemplateSMS =
              item.IsTemplateSMS == 1 ? true : false;
            this.SMSChannelType = item.SMSChannelType;
            if (!isFirst) {
              this.handleIsTemplateSMS(this.formData.IsTemplateSMS);
            }
          }
        });
      }
      if (this.formData.MessageChannelType === "企业微信") {
        this.channelList.forEach((item) => {
          if (item._id === value) {
            this.AgentId = item.AgentId;
          }
        });
        if (!isFirst) {
          if (this.AgentId) {
            this.formData.chat_type = "employee";
          } else {
            this.formData.chat_type = "";
          }
        }
      }
    },
    changeMessageType(value, isFirst) {
      this.$refs.form.clearValidate();
      if (["微信公众号"].indexOf(this.formData.MessageChannelType) > -1) {
        if (!isFirst) {
          if (value === "图片消息") {
            this.formData.media_ids_图片消息 = [];
          } else {
            this.formData.media_ids = "";
          }
          if (this.formData.MessageType === "模板消息") {
            this.formData.TemplateParam = [];
          }
        }
        this.metaPagination.offset = 0;
        this.metaMore = true;
        this.metaLoading = false;
        this.getMediaList(value);
        this.getWechatMessageList(isFirst, this.formData.MessageChannel);
      }
    },
    handleTypeChange(channel) {
      this.formData.MessageChannelType = channel.name;
      this.formData.MessageChannel = "";
      if (
        this.formData.MessageChannelType === "企业微信" ||
        (this.formData.MessageChannelType === "微信公众号" &&
          this.formData.MessageType === "文本消息")
      ) {
        this.formData.TemplateParam_文本消息 = "";
      }
      this.formData.chat_type = "";
      this.AgentId = "";
      this.$refs.form.clearValidate();
    },
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleAddCondCust() {
      this.formData.TemplateParam.push({
        id: util.randomString(6),
        variable: "",
        value: "",
        default: "",
      });
    },
    handleRemoveCondCust(detail) {
      const index = this.formData.TemplateParam.indexOf(detail);
      this.formData.TemplateParam.splice(index, 1);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      if (this.saving) {
        return;
      }
      let op = null;
      let param = {
        ...this.formData,
      };
      delete param.safe;
      if (
        ["短信", "企业微信", "APP", "微信小程序"].indexOf(
          this.formData.MessageChannelType
        ) > -1
      ) {
        delete param.MessageType;
      }

      if (this.formData.MessageChannelType === "企业微信") {
        op = this.$refs.companyWxRef && this.$refs.companyWxRef.getFormData();
        if (!op || (!op.TemplateParam && !op.Attachments.length)) {
          this.$message.error('消息内容至少要有文本或者图片！')
          return;
        }
        param.TemplateParam = op.TemplateParam;
        param.Attachments = op.Attachments;
        if (op.MessageType) {
          param.MessageType = op.MessageType;
        }

        if (this.AgentId) {
          param.safe = this.formData.safe;
        }
        delete param.RedirectURL;
      }

      if (this.formData.MessageChannelType === "微信公众号") {
        if (this.formData.MessageType === "模板消息") {
          const list = [];
          param.TemplateParam.forEach((item) => {
            list.push({
              variable: item.variable,
              default: item.default,
              value: `{{${item.value}}}`,
            });
          });
          param.TemplateParam = list;
        } else {
          if (this.formData.MessageType !== "图片消息") {
            param.media_ids = [param.media_ids];
          }
          if (this.formData.MessageType === "图片消息") {
            param.media_ids = param.media_ids_图片消息;
          }
          if (this.formData.MessageType === "文本消息") {
            if (param.TemplateParam_文本消息 === "") {
              this.$message.error("请输入文本消息！");
              return false;
            }
            param.TemplateParam = param.TemplateParam_文本消息;
          }

          delete param.RedirectURL;
        }
      }

      if (this.formData.MessageChannelType === "APP") {
        op = this.$refs.appRef && this.$refs.appRef.getFormData();
        if (!op) {
          return;
        }
        param = Object.assign(param, { ...op });
        delete param.RedirectURL;
      }

      if (["微信小程序"].indexOf(this.formData.MessageChannelType) > -1) {
        const list = [];
        param.TemplateParam.forEach((item) => {
          list.push({
            variable: item.variable,
            default: item.default,
            value: `{{${item.value}}}`,
          });
        });
        param.TemplateParam = list;
      }

      if (["短信"].indexOf(this.formData.MessageChannelType) > -1) {
        param.IsTemplateSMS = param.IsTemplateSMS ? 1 : 0;

        if (param.IsTemplateSMS) {
          const listTwo = [];
          param.TemplateParam.forEach((item) => {
            listTwo.push({
              variable: item.variable,
              default: item.default,
              value: `{{${item.value}}}`,
            });
          });
          param.TemplateParam = listTwo;
          delete param.RedirectURL;
        } else {
          op =
            this.$refs.textMessageRef &&
            this.$refs.textMessageRef.getFormData();
          if (!op) {
            return;
          }
          param = Object.assign(param, { ...op });
        }
      }

      delete param.TemplateParam_文本消息;
      delete param.media_ids_图片消息;

      this.saving = true;

      let prom;
      if (this.saveType === "add") {
        prom = apiAddTemplate(param);
      } else {
        prom = apiUpdateTemplate(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.formData = {
        Name: "",
        MessageChannelType: "短信",
        MessageChannel: "",
        MessageType: "模板消息",
        TemplateId: "",
        TemplateParam: [],
        RedirectURL: "",
        MessageTags: [],
        Attachments: [],
        WechatAppID: "",
        MiniProgramRedirectPage: "",
        MessageTitle: "",
        RedirectOption: "",
        RedirectOptionValue: "",
        chat_type: "",
        media_ids_图片消息: [],
        TemplateParam_文本消息: "",
        safe: false,
        IsTemplateSMS: true,
        RelatedAppletAppId: "",
        RelatedAppletAppUrl: "",
      };
      this.mediaMap = {
        图文消息: [],
        视频消息: [],
        语音消息: [],
        图片消息: [],
      };
    },
    getTemplateDetail() {
      if (this.saveType === "edit" || this.saveType === "show") {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        return apiGetTemplateDetail(this.detail._id)
          .then((res) => {
            if (res.MessageChannelType !== "企业微信") {
              if (
                (res.MessageChannelType === "微信公众号" &&
                  res.MessageType === "文本消息") ||
                res.MessageChannelType === "APP" ||
                (res.MessageChannelType === "短信" && !res.IsTemplateSMS)
              ) {
                console.log(res.TemplateParam);
              } else {
                try {
                  res.TemplateParam.forEach((item) => {
                    if (
                      (["微信公众号"].indexOf(res.MessageChannelType) > -1 &&
                        res.MessageType === "模板消息") ||
                      ["微信小程序"].indexOf(res.MessageChannelType) > -1
                    ) {
                      item.variableDisabled = true; // 变量不能编辑
                      item.canNotClose = true; // 不允许关闭（删除）
                    }
                    item.value = item.value.replace("{{", "").replace("}}", "");
                  });
                } catch (error) {
                  console.log(error);
                }
              }

              let media_ids = "";
              let media_ids_图片消息 = [];
              let TemplateParam_文本消息 = "";
              let TemplateParam = res.TemplateParam;
              if (res.MessageChannelType === "微信公众号") {
                if (res.MessageType === "图片消息") {
                  media_ids_图片消息 = res.media_ids || [];
                } else {
                  media_ids =
                    res.media_ids && res.media_ids.length && res.media_ids[0];
                }
                if (res.MessageType === "文本消息") {
                  TemplateParam_文本消息 = res.TemplateParam;
                  TemplateParam = [];
                }
              }
              Object.assign(this.formData, {
                _id: res._id,
                Name: res.Name,
                MessageChannelType: res.MessageChannelType,
                MessageChannel: res.MessageChannel,
                MessageType: res.MessageType,
                TemplateId: res.TemplateId,
                TemplateParam: TemplateParam,
                RedirectURL: res.RedirectURL,
                MessageTags: res.MessageTags,
                MiniProgramRedirectPage: res.MiniProgramRedirectPage,
                WechatAppID: res.WechatAppID,
                media_ids: media_ids,
                media_ids_图片消息: media_ids_图片消息,
                TemplateParam_文本消息: TemplateParam_文本消息,
                MessageTitle: res.MessageTitle,
                RedirectOption: res.RedirectOption,
                RedirectOptionValue: res.RedirectOptionValue,
                IsTemplateSMS: res.IsTemplateSMS == 1 ? true : false,
                RelatedAppletAppId: res.RelatedAppletAppId,
                RelatedAppletAppUrl: res.RelatedAppletAppUrl,
              });

              this.changeMessageChannel(res.MessageChannel, true);
              this.$nextTick(() => {
                this.$refs.textMessageRef &&
                  this.$refs.textMessageRef.setDefaultValue(this.formData);
              });
            } else {
              Object.assign(this.formData, {
                _id: res._id,
                Name: res.Name,
                MessageChannelType: res.MessageChannelType,
                MessageChannel: res.MessageChannel,
                MessageType: res.MessageType,
                TemplateId: res.TemplateId,
                RedirectURL: res.RedirectURL,
                MessageTags: res.MessageTags,
                chat_type: res.chat_type,
                safe: res.safe,
              });

              this.changeMessageChannel(res.MessageChannel, true);

              this.$nextTick(() => {
                this.$refs.companyWxRef &&
                  this.$refs.companyWxRef.default(
                    cloneDeep(res.Attachments),
                    res.TemplateParam
                  );
              });
            }
          })
          .finally(() => {
            this.getDetailLoading = false;
          });
      }
    },
    channelActive(channels) {
      const list = [];
      channels.forEach((item) => {
        if (item.IsActive == 0) {
          return;
        }
        list.push(item);
      });
      return list;
    },
    getChannelList(type) {
      if (type === "短信" && this.dxChannels.length > 0) return;
      if (type === "微信公众号" && this.gzhChannels.length > 0) return;
      if (type === "企业微信" && this.companyWxChannels.length > 0) return;
      if (type === "微信小程序" && this.miniProgramChannels.length > 0) return;
      if (type === "APP" && this.appChannels.length > 0) return;
      const param = {
        offset: 0,
        limit: 1000,
      };
      return apiGetChannelListByType(type, param).then((res) => {
        if (type === "短信") {
          this.dxChannels = this.channelActive(res.channels);
        } else if (type === "微信公众号") {
          this.gzhChannels = this.channelActive(res.channels);
        } else if (type === "企业微信") {
          this.companyWxChannels = this.channelActive(res.channels);
        } else if (type === "微信小程序") {
          this.miniProgramChannels = this.channelActive(res.channels);
        } else if (type === "APP") {
          this.appChannels = this.channelActive(res.channels);
        }
      });
    },
    // 企业微信测试发送
    sendWxMessage(content) {
      if (!content || !content.length) {
        this.$message.error('发送内容不能为空！')
      }
      this.conpanyWxSendContent = content;
    },
    // app测试发送
    sendAppMessage(content) {
      this.appSendContent = content;
    },
    // 加载更多微信公众号某个渠道素材
    loadMediaMore() {
      if (this.getEnd()) {
        return;
      }
      this.metaPagination.offset += 1;
      this.getMediaList();
    },
    getEnd() {
      return !this.metaMore || this.metaLoading;
    },
    // 获取微信公众号某个渠道素材列表
    getMediaList() {
      const type = this.formData.MessageType;
      const channelId = this.formData.MessageChannel;
      if (channelId && type) {
        let typeEn = "";
        if (type === "图文消息") {
          typeEn = "news";
        }
        if (type === "视频消息") {
          typeEn = "video";
        }
        if (type === "语音消息") {
          typeEn = "voice";
        }
        if (type === "图片消息") {
          typeEn = "image";
        }
        if (!typeEn) {
          return;
        }
        this.metaLoading = true;
        apiGetMediaList(channelId, {
          type: typeEn,
          limit: this.metaPagination.limit,
          offset: this.metaPagination.offset * this.metaPagination.limit,
        })
          .then((res) => {
            if (res.length) {
              this.metaMore = true;
              res.forEach((item) => {
                const find = this.mediaMap[type].find((old) => {
                  return old.media_id === item.media_id;
                });
                if (!find) {
                  this.mediaMap[type].push(item);
                }
              });
            } else {
              this.metaMore = false;
            }
            this.$forceUpdate();
          })
          .finally(() => {
            this.metaLoading = false;
          });
      }
    },
    // 获取APP某个渠道的自定义参数列表
    getAppChannelFields(channelId, isFirst) {
      apiGetChannelDetail(channelId).then((res) => {
        // todo 编辑时候初始化问题
        this.appKeys = [];
        Object.keys(res.extras_keys).forEach((key) => {
          this.appKeys.push({
            field: "",
            placeholder: res.extras_keys[key] || "请输入",
            content: "",
            key: key,
          });
        });
        // 编辑时候，初始化值，之所以这边处理，是因为，我们需要先获取参数列表
        this.$nextTick(() => {
          if (
            (this.saveType === "edit" || this.saveType === "show") &&
            isFirst
          ) {
            this.$refs.appRef &&
              this.$refs.appRef.setDefaultValue(this.formData);
          }
        });
      });
    },
    getWechatMessageList(isFirst, channelId) {
      // 获取变量列表，正则匹配 {{xx}} 里的 xx。
      /**
       * (?<=\{\{})   匹配以左花括号开头
          [^}]*    取得内容
          (?=\}\})   匹配以右花括号结束
       */
      const reg = /(?<=\{\{)[^}]*(?=\}\})/g;
      this.wechatMessageList = [];
      if (!isFirst) {
        this.formData.TemplateId = "";
      }

      apiGetWechatMessageList(channelId).then((res) => {
        res.forEach((item) => {
          item.TemplateParam = [];
          const list = item.content.match(reg) || [];
          list.forEach((variable) => {
            const arr = variable.split(".");
            item.TemplateParam.push({
              id: util.randomString(6),
              variable: arr[0],
              value: "",
              default: "",
              variableDisabled: true, // 变量不能编辑
              canNotClose: true, // 不允许关闭（删除）
            });
          });
        });

        this.wechatMessageList = res;
      });
    },
  },
  async created() {
    let type = ["短信", "微信公众号", "企业微信", "微信小程序", "APP"];
    if (this.saveType === "edit" || this.saveType === "show") {
      if (this.saveType === "show") {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.getDetailLoading = true;
      if (this.MessageChannelType) {
        type = type.filter((name) => {
          return name !== this.MessageChannelType;
        });
        await this.getChannelList(this.MessageChannelType);
      }
      await this.getTemplateDetail();
    }
    type.forEach((name) => {
      this.getChannelList(name);
    });
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    // width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: 10px;
  }

  .drawer-content {
    position: relative;
    /deep/ .el-form-item__label {
      font-size: 13px;
    }
  }

  .companyTemplate {
    position: absolute;
    right: 25px;
    top: 74px;
  }
  .contentVariable {
    /deep/ .cond-item-wrap {
      margin-bottom: 0px;
    }
  }
}
</style>
